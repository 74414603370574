<template>
  <div class="card flex flex-col gap-[12px] h-full bg-canvas rounded-2xl overflow-hidden">
    <NuxtLink :href="href" type="button" class="flex-grow">
      <div class="flex flex-col gap-[12px] flex-grow">
        <div class="w-full" :class="[highlight ? 'h-[256px]' : 'h-[156px]']">
          <img
            :src="
              useStoryblokImageService(
                article.content.header.filename,
                {
                  fitIn: true,
                },
                { filters: { quality: 100 } }
              )
            "
            class="h-full w-full bg-cover bg-center bg-no-repeat"
            :alt="article.content.header.filename?.title"
          />
        </div>
        <div v-if="highlight" class="px-4 pt-2">
          <span class="text-condensed-light text-nowrap px-1">{{ lastUpdatedAt }}</span>
          <span v-for="category in categories" :key="category.uuid" class="uppercase text-condensed">
            / &lt; {{ category.name }} &#47;&gt;
          </span>
        </div>
        <div v-if="highlight" class="flex flex-row flex-wrap gap-1 px-4">
          <BlogTags :tags="props.article.content.tags ?? []" />
        </div>
        <div
          class="title text-extended-bold text-wrap line-clamp-2 px-4"
          :class="[landingPage ? 'pt-3 px-6 md:pb-0 pb-3' : '']"
        >
          {{ article.content.title }}
        </div>
        <div v-if="highlight && !landingPage" class="text-light text-wrap line-clamp-2 px-4">
          {{ article.content.description }}
        </div>
      </div>
    </NuxtLink>
    <NuxtLink v-if="highlight" :href="authorHref" type="button">
      <div class="flex flex-row gap-4 p-4">
        <StoryblokImage class="rounded-full" :image="authorStory.content.image" :width="40" :height="40" grayscale />
        <div class="flex flex-col">
          <span class="text-bold">{{ authorStory.content.name }}</span>
          <span class="text-light">{{ authorStory.content.title }}</span>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { BlogArticleStory } from '@/types/storyblok';
import { useStoryblokImageService } from '@/utils/storyblok/image';
import { useI18n } from 'vue-i18n';
import { getResolvedField, getResolvedArrayField } from '@/utils/storyblok/resolved';
import BlogTags from '@/components/blog/BlogTags.vue';
import { dateFromString, formatDate } from '@/utils/date';

const i18n = useI18n();

const props = defineProps({
  article: { type: Object as PropType<BlogArticleStory>, required: true },
  highlight: { type: Boolean, default: false },
  landingPage: { type: Boolean, default: false },
});

const href = computed(() => `/${i18n.locale.value}/blog/${props.article.slug}`);

const authorStory = getResolvedField(props.article.content.author);
const authorHref = computed(() => `/${i18n.locale.value}/blog/authors/${authorStory.slug}`);
const categories = getResolvedArrayField(props.article.content.categories ?? []);

const lastUpdatedAt = computed(() => {
  const date = dateFromString(
    props.article.first_published_at || props.article.published_at || props.article.created_at
  );
  return date && formatDate(date, 'LL');
});
</script>

<style scoped lang="scss">
.card:hover {
  .title {
    color: var(--vvd-color-cta-700);
  }
}
</style>
